<template>
  <div>
    <v-container>
      <v-row class="mb-4">
        <h2>Introductory Information</h2>
      </v-row>

      <v-simple-table>
        <thead>
          <tr v-for="field in fields" :key="field">
            <td style="width: 220px">
              {{ field.name }}
            </td>
            <td>
              <v-text-field v-model="inputs[field.inputKey]" />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'UOttawaTask4',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        title: null,
        author: null,
        partner: null,
        demonstrator: null,
        date: null,
      },
      fields: [
        {name: 'Experiment Title: ', inputKey: 'title'},
        {name: 'Author(s) Name(s): ', inputKey: 'author'},
        {name: "Submitting Author's Partner: ", inputKey: 'partner'},
        {name: "TA's (Demonstrator's) Name: ", inputKey: 'demonstrator'},
        {name: 'Date Experiment Performed: ', inputKey: 'date'},
      ],
    };
  },
};
</script>

<style scoped></style>
